import { createClient } from '@liveblocks/client'
import LiveblocksProvider from '@liveblocks/yjs'
import { Doc } from 'yjs'
import md5 from 'blueimp-md5'
import type { DocumentOptions, Presence, RoomData, RoomOptions, TypedProvider, TypedRoom } from '~/types/liveblocks'

export default defineNuxtPlugin({
    parallel: true,
    setup: () => {
        const { public: { liveBlocksApiKey } } = useRuntimeConfig()
        if (!liveBlocksApiKey)
            return

        const client = createClient({
            publicApiKey: liveBlocksApiKey,
        })
        const rooms: Record<string, TypedRoom> = {}
        const yDocs: Record<string, Doc> = {}
        const yDocProviders: Record<string, TypedProvider> = {}
        const { myUsers, otherUsers } = useLiveBlocks()
        const unsubscribeToMyPresence: Record<string, () => void> = {}
        const unsubscribeToOthersPresence: Record<string, () => void> = {}

        function hashRoomId(id: string) {
            return md5(id)
        }

        function enterRoom(data: RoomData, options?: RoomOptions) {
            const { id, presence, storage } = data
            rooms[id] = client.enter(
                hashRoomId(id),
                {
                    initialPresence: presence,
                    initialStorage: storage,
                },
            )
            if (options?.documentOptions)
                initializeYDocAndProvider(id, options.documentOptions)
            if (options?.subscribeToMyPresence) {
                myUsers.value[id] = rooms[id].getPresence()
                subscribeToMyPresence(id)
            }
            if (options?.subscribeToOthersPresence) {
                otherUsers.value[id] = rooms[id].getOthers()
                subscribeToOthersPresence(id)
            }
        }

        function leaveRoom(roomId: string) {
            unsubscribeToMyPresence[roomId]?.()
            unsubscribeToOthersPresence[roomId]?.()
            yDocProviders[roomId]?.destroy()
            yDocs[roomId]?.destroy()
            client.leave(roomId)

            delete myUsers.value[roomId]
            delete otherUsers.value[roomId]
            delete unsubscribeToMyPresence[roomId]
            delete unsubscribeToOthersPresence[roomId]
            delete yDocProviders[roomId]
            delete yDocs[roomId]
            delete rooms[roomId]
        }

        function subscribeToMyPresence(roomId: string) {
            unsubscribeToMyPresence[roomId] = rooms[roomId].subscribe(
                'my-presence',
                (newPresence) => {
                    myUsers.value[roomId] = newPresence
                },
            )
        }

        function subscribeToOthersPresence(roomId: string) {
            unsubscribeToOthersPresence[roomId] = rooms[roomId].subscribe(
                'others',
                (newOthers) => {
                    otherUsers.value[roomId] = newOthers
                },
            )
        }

        function initializeYDocAndProvider(roomId: string, options: DocumentOptions) {
            const { docLoadingState } = options
            yDocs[roomId] = new Doc()
            yDocProviders[roomId] = new LiveblocksProvider(
                rooms[roomId],
                yDocs[roomId],
            )
            docLoadingState.value = true
            yDocProviders[roomId].once('synced', () => {
                docLoadingState.value = false
            })
        }

        function updatePresence(roomId: string, presence: Presence) {
            rooms[roomId].updatePresence(presence)
        }

        return {
            provide: {
                liveBlocks: {
                    rooms,
                    yDocs,
                    yDocProviders,
                    enterRoom,
                    leaveRoom,
                    updatePresence,
                    initializeYDocAndProvider,
                },
            },
        }
    },
})
