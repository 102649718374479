const _transparent = "transparent"
const _current = "currentColor"
const _black = {"400":"#9A9DA1","560":"#71757B","700":"#4E535A","800":"#353A42","900":"#1B222B","1000":"#020913","DEFAULT":"#1B222B"}
const _white = {"400":"rgba(255, 255, 255, 0.4)","600":"rgba(255, 255, 255, 0.6)","800":"rgba(255, 255, 255, 0.8)","1000":"#FFFFFF","DEFAULT":"#FFF"}
const _gray = {"20":"#FAFAFB","40":"#F5F6F6","50":"rgb(var(--color-gray-50) / <alpha-value>)","60":"#F0F0F1","80":"#EBECED","100":"rgb(var(--color-gray-100) / <alpha-value>)","120":"#E1E3E4","160":"#D7D9DB","200":"rgb(var(--color-gray-200) / <alpha-value>)","240":"#C2C4C6","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)","DEFAULT":"#EBECED"}
const _orange = {"300":"#FFE2C7","1000":"#FF9E45","DEFAULT":"#FF9E45"}
const _yellow = {"300":"#FEEFC8","1000":"#FDCA46","DEFAULT":"#FDCA46"}
const _cyan = {"300":"#C6EAF5","1000":"#40B7DF","DEFAULT":"#40B7DF"}
const _blue = {"300":"#C4CAE9","1000":"#3A4FB4","DEFAULT":"#3A4FB4"}
const _positive = {"bright":"#DBFFF0","DEFAULT":"#34BA82","dark":"#1F704A"}
const _caution = {"bright":"#FFEFDB","DEFAULT":"#F3A743","dark":"#926429"}
const _negative = {"bright":"#FFDBDB","DEFAULT":"#EF4343","dark":"#8F2828"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _secondary = {"500":"#08344D","DEFAULT":"#08344D"}
const _bordercolor = {"500":"#EBEBEB","DEFAULT":"#EBEBEB"}
const _dark = {"50":"#c5c5c5","100":"#c3c3c3","200":"#ababab","300":"#878787","400":"#555555","500":"#343434","600":"#1c1c1c","700":"#080808","800":"#050505","900":"#0b0b0b","950":"#111111"}
const _base = {"500":"#f6f6f6","600":"#ebebeb","700":"#d9d9d9","800":"#cbcbcb","900":"#bdbdbd","950":"#a0a0a0","darkish":"#ebebeb","darker":"#d9d9d9","darkest":"#cbcbcb","DEFAULT":"#f6f6f6"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "gray": _gray, "orange": _orange, "yellow": _yellow, "cyan": _cyan, "blue": _blue, "positive": _positive, "caution": _caution, "negative": _negative, "red": _red, "secondary": _secondary, "bordercolor": _bordercolor, "dark": _dark, "base": _base, "primary": _primary, "cool": _cool,  }
export { config as default, _transparent, _current, _black, _white, _gray, _orange, _yellow, _cyan, _blue, _positive, _caution, _negative, _red, _secondary, _bordercolor, _dark, _base, _primary, _cool }