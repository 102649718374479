import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45any2table_45global from "/vercel/path0/packages/application/middleware/auth-any2table.global.ts";
import auth_45global from "/vercel/path0/packages/application/middleware/auth.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45any2table_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}