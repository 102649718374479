import { pgTable, primaryKey, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import type { Static } from '@sinclair/typebox'
import { workspace } from './workspace'
import { tableToSchema } from './shared'

export const userWorkspace = pgTable('user_workspace', {
    userEmail: varchar('user_email', { length: 256 }).notNull(),
    workspaceId: uuid('workspace_id').notNull().references(() => workspace.id, { onDelete: 'cascade' }),
}, table => ({
    pk: primaryKey({ columns: [table.userEmail, table.workspaceId] }),
}))

export const userWorkspaceRelations = relations(userWorkspace, ({ one }) => ({
    workspace: one(workspace, {
        fields: [userWorkspace.workspaceId],
        references: [workspace.id],
    }),
}))

const { selectSchema } = tableToSchema(userWorkspace)

export const selectUserWorkspaceSchema = selectSchema
export type UserWorkspace = Static<typeof selectSchema>
