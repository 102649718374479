<script setup lang="ts">
const props = defineProps<{
    name: string
    size?: 'xs' | 'sm' | 'md' | 'lg' | string
}>()

const iconSize = computed(() => {
    if (props.size && props.size.endsWith('px')) {
        return props.size
    }
    switch (props.size) {
        case 'xs':
            return '8px'
        case 'sm':
            return '12px'
        case 'lg':
            return '24px'
        case 'md':
        default:
            return '16px'
    }
})
</script>

<template>
    <div :class="[name]" :style="{ 'width': iconSize, 'height': iconSize, 'mask-size': 'cover' }" />
</template>
