import revive_payload_client_ZLJmPKQ7i9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ql8LCiANtF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NlzcFVxCfm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_n1UYWxa88G from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_oQAHw36xGJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/application/.nuxt/components.plugin.mjs";
import prefetch_client_ASsjdVEEpH from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_lryZT7ycnk from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+instru_nvzqqiduxkuk2woavbcaqcnkcy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/packages/application/.nuxt/sentry-client-config.mjs";
import plugin_client_zKByvCvyaV from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.6/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_prod_client_zgeSuGMxNz from "/vercel/path0/node_modules/.pnpm/nuxt-monaco-editor@1.2.9_monaco-editor@0.49.0_vite@5.3.1/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_client_TCj4rnBwGV from "/vercel/path0/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3_vue@3.4.27/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import slideovers_r4hytRz9fn from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_vite@5.3.1_vue@3.4.27/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_Et5nRn4JaU from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_vite@5.3.1_vue@3.4.27/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_zEZUtr3pnd from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_vite@5.3.1_vue@3.4.27/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_zhpI4vp1Fk from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/packages/application/.nuxt/unocss.mjs";
import chunk_reload_client_xL15YPaOX5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@types+node@18.19.33_drizzle-orm@0.31.0_eslint@9.4.0_typ_rruo4xlilzhczpeulw6noxjb64/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_force_logout_per_revision_TeCF2tpkEo from "/vercel/path0/packages/application/plugins/0.force-logout-per-revision.ts";
import feature_flags_3X7A4FWWIa from "/vercel/path0/packages/application/plugins/feature-flags.ts";
import input_autowidth_bJoGoxmQ7Q from "/vercel/path0/packages/application/plugins/input-autowidth.ts";
import liveblocks_ch8igrfBKq from "/vercel/path0/packages/application/plugins/liveblocks.ts";
import test_id_TLE30LqioL from "/vercel/path0/packages/application/plugins/test-id.ts";
import vue_clerk_NNPdTlBnWo from "/vercel/path0/packages/application/plugins/vue-clerk.ts";
export default [
  revive_payload_client_ZLJmPKQ7i9,
  unhead_ql8LCiANtF,
  router_NlzcFVxCfm,
  payload_client_n1UYWxa88G,
  check_outdated_build_client_oQAHw36xGJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ASsjdVEEpH,
  sentry_client_lryZT7ycnk,
  sentry_client_config_o34nk2sJbg,
  plugin_client_zKByvCvyaV,
  plugin_prod_client_zgeSuGMxNz,
  plugin_client_TCj4rnBwGV,
  plugin_ziYcKwnJdq,
  slideovers_r4hytRz9fn,
  modals_Et5nRn4JaU,
  colors_zEZUtr3pnd,
  plugin_client_zhpI4vp1Fk,
  unocss_MzCDxu9LMj,
  chunk_reload_client_xL15YPaOX5,
  _0_force_logout_per_revision_TeCF2tpkEo,
  feature_flags_3X7A4FWWIa,
  input_autowidth_bJoGoxmQ7Q,
  liveblocks_ch8igrfBKq,
  test_id_TLE30LqioL,
  vue_clerk_NNPdTlBnWo
]