<script setup lang="ts">
const { public: config } = useRuntimeConfig()

const element = ref<HTMLElement>()

const isOpen = ref(false)

const information = computed(() => {
    return [
        { label: 'Base URL', value: config.baseUrl },
        { label: 'API URL', value: config.apiUrl },
        { label: 'Proxy URL', value: config.proxyUrl },
        { label: 'Commit Hash', value: config.commitHash || 'Works on your machine' },
    ]
})
</script>

<template>
    <div
        v-if="config.isNotProduction"
        ref="element"
        bg="#031E22/94" p-12px rounded-12px cursor-pointer
        transition
        @dblclick="isOpen = !isOpen"
    >
        <Transition name="appear">
            <div v-if="!isOpen" b="2 white" rounded-4px h-16px w-16px />
        </Transition>
        <Transition name="appear">
            <div v-if="isOpen" h="[calc(100dvh-48px)]" w-50dvh flex="~ col" gap-16px text-white z-55>
                <div
                    v-for="{ label, value } in information"
                    :key="label"
                    flex="~ col" gap-6px
                >
                    <div text="16px" leading-1.2em font-bold>
                        {{ label }}
                    </div>
                    <div text="16px" leading-1.2em font-mono bg="white/5" p-4px>
                        {{ value }}
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
