export default defineNuxtRouteMiddleware((to) => {
    if (!to.path.includes('/any2table'))
        return

    if (process.server) {
        const unprotectedRoutes = [
            '/any2table',
            '/any2table/verify',
        ]

        const any2TableCookie = useCookie('any2table.session-auth')

        if (unprotectedRoutes.includes(to.path) && any2TableCookie.value)
            return navigateTo('/any2table/app')

        if (!unprotectedRoutes.includes(to.path) && !any2TableCookie.value)
            return navigateTo('/any2table')
    }
})
