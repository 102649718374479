import * as Sentry from '@sentry/nuxt'

// all envs with `VITE_SENTRY_` prefix are defined on infisical
Sentry.init({
    enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
})
Sentry.setTag('client', true)
