import type { WatchCallback, WatchOptions, WatchSource } from 'vue'

export function watchUnique<T, Immediate extends Readonly<boolean> = false>(
    source: WatchSource<T>,
    callback: WatchCallback<T, Immediate extends true ? T | undefined : T>,
    options: WatchOptions<Immediate> & { key: string; server?: boolean },
) {
    const keyState = useState(`watch-once-${options?.key}`, () => false)

    if (options?.server || process.client) {
        if (keyState.value)
            return

        watch(source, callback, options)

        keyState.value = true

        if (getCurrentInstance() !== null) {
            onUnmounted(() => {
                keyState.value = false
            })
        }
    }
}
