/* eslint-disable no-console */
import { Axiom } from '@axiomhq/js'
import consola from 'consola'
import type { Workspace } from 'tela-api/src/database/schemas'

type LogLevel = 'debug' | 'info' | 'warn' | 'error'

export function useLog() {
    const config = useRuntimeConfig()

    const axiomInstance = new Axiom({
        token: config.public.axiom.token,
        orgId: config.public.axiom.orgId,
    })

    const currentWorkspace = useState<Workspace | null>('workspace', () => null)

    const logFn = (level: LogLevel) => (message: string, body?: object) => config.public.isDevelopment
        ? consola.withTag('dev').withTag(new Date().getTime().toString())[level](message, body)
        : axiomInstance.ingest(config.public.axiom.dataset, {
            level,
            message,
            body: body && stringifyLogBody(body as Record<string, unknown>),
            metadata: {
                workspace: currentWorkspace.value?.id,
                session: getSessionId(),
            },
        })

    axiomInstance.onError = error => logFn('error')('Error sending log to Axiom', error instanceof Error
        ? {
                message: error.message,
                stack: error.stack,
                details: 'details' in error ? error.details : undefined,
            }
        : error)

    function debugLog(message: string, ...args: any[]) {
        if (!config.public.isDevelopment)
            return

        console.group(message)
        for (const arg of args) {
            console.log(arg)
        }
        console.groupEnd()
    }
    return {
        debug: debugLog,
        info: logFn('info'),
        warn: logFn('warn'),
        error: logFn('error'),
    }
}

function stringifyLogBody(body: Record<string, unknown>) {
    const tmp = { ...body }
    Object.keys(body).forEach((key) => {
        if (body[key] !== null && typeof body[key] === 'object')
            tmp[key] = JSON.stringify(body[key], null, 2)
    })

    return tmp
}

function getSessionId() {
    if (process.server)
        return randomId()

    const stored = sessionStorage.getItem('tela-user-session-id')
    if (stored)
        return stored

    const newSessionId = randomId()
    sessionStorage.setItem('tela-user-session-id', newSessionId)
    return newSessionId
}
