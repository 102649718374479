import { useAuth } from 'vue-clerk'

export default defineNuxtRouteMiddleware((to) => {
    const { isSignedIn } = useAuth()
    const env = useRuntimeConfig()

    if (to.path.includes('/any2table'))
        return

    const isAuthenticated = Boolean(isSignedIn.value || env.public.histoire)
    const publicRoutes = [
        '/app/login',
        '/',
    ]

    const isGoingToPublicRoute = publicRoutes.includes(to.path)

    if (isGoingToPublicRoute && isAuthenticated)
        return navigateTo('/app')

    if (!isGoingToPublicRoute && !isAuthenticated)
        return navigateTo('/app/login')
})
