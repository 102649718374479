import type { MyUser, OtherUser } from '~/types/liveblocks'

export function useLiveBlocks() {
    const myUsers = useState<Record<string, MyUser>>(
        'my-users',
        () => ({} as Record<string, MyUser>),
    )
    const otherUsers = useState<Record<string, readonly OtherUser[]>>(
        'other-users',
        () => ({} as Record<string, readonly OtherUser[]>),
    )

    return {
        myUsers,
        otherUsers,
    }
}
