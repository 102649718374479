import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLayout, LazyOtpCode, LazyTeamInvite, LazyWorkspaceInvite, LazyIcon, LazyIconCSS, LazyDataExtraction } from '#components'
const lazyGlobalComponents = [
  ["Layout", LazyLayout],
["OtpCode", LazyOtpCode],
["TeamInvite", LazyTeamInvite],
["WorkspaceInvite", LazyWorkspaceInvite],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
["DataExtraction", LazyDataExtraction],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
