import { Type as t } from '@sinclair/typebox'
import type { TObject, TOmit, TProperties, TSchema } from '@sinclair/typebox'
import type { PgTableWithColumns, TableConfig } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-typebox'

export function tableToSchema<T extends TableConfig>(table: PgTableWithColumns<T>) {
    // @ts-expect-error Recursive????
    const selectSchema = t.Composite([
        createSelectSchema(table),
    ])

    // @ts-expect-error Recursive????
    const insertSchema = t.Composite([
        createInsertSchema(table),
    ])

    return {
        selectSchema,
        insertSchema,
    }
}

export function overrideType<S extends TSchema, R extends TProperties>(schema: S, replacements: R) {
    const keys = Object.keys(replacements as any) as (keyof S)[]
    const omittedSchema = t.Omit(schema, keys as any) as any as TOmit<S, keyof R>
    const replacementSchema = t.Object(replacements as any) as TObject<R>
    return t.Intersect([omittedSchema, replacementSchema])
}

export function withoutKeys<T, K extends (keyof T)[]>(obj: T, keys: K): Omit<T, K[number]> {
    const result: any = {}
    for (const key in obj) {
        if (!keys.includes(key as any))
            result[key] = obj[key]
    }
    return result
}

// This type is located in the shared file to avoid circular dependencies, as it is used in multiple locations.
export enum EvaluatorKeys {
    length = 'length',
    similarity = 'similarity',
    modelGraded = 'model-graded',
    regex = 'regex',
}
export const evaluatorKeys = t.Enum(EvaluatorKeys)
