import { default as indexVLFNh06M3cMeta } from "/vercel/path0/packages/application/pages/any2table/app/index.vue?macro=true";
import { default as indexidQkrUayBeMeta } from "/vercel/path0/packages/application/pages/any2table/index.vue?macro=true";
import { default as verifyAOMrCvjl9aMeta } from "/vercel/path0/packages/application/pages/any2table/verify.vue?macro=true";
import { default as dashboard0TrLSEL7kfMeta } from "/vercel/path0/packages/application/pages/app/dashboard.vue?macro=true";
import { default as errorVURPiQTj3uMeta } from "/vercel/path0/packages/application/pages/app/error.vue?macro=true";
import { default as _91fragment_45id_93vXfbKZK7kLMeta } from "/vercel/path0/packages/application/pages/app/fragment/[fragment-id].vue?macro=true";
import { default as indexVoNNwFj74nMeta } from "/vercel/path0/packages/application/pages/app/fragment/index.vue?macro=true";
import { default as indexzrWJlt7TpMMeta } from "/vercel/path0/packages/application/pages/app/index.vue?macro=true";
import { default as loginAXWETL6ll0Meta } from "/vercel/path0/packages/application/pages/app/login.vue?macro=true";
import { default as _91project_45id_93H0nA0FmKtJMeta } from "/vercel/path0/packages/application/pages/app/project/[project-id].vue?macro=true";
import { default as craftXo9E2wrweKMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/craft.vue?macro=true";
import { default as evaluationjKyqfSjFrGMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/evaluation.vue?macro=true";
import { default as prompt_45app4k08kzEi5pMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/prompt-app.vue?macro=true";
import { default as testcXfqT28NoQMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/test.vue?macro=true";
import { default as _91prompt_45id_939umtKeSLaBMeta } from "/vercel/path0/packages/application/pages/app/prompt/[prompt-id].vue?macro=true";
import { default as indexVtpaDeze5nMeta } from "/vercel/path0/packages/application/pages/app/settings/index.vue?macro=true";
import { default as membersezEMPcj8bQMeta } from "/vercel/path0/packages/application/pages/app/settings/members.vue?macro=true";
import { default as workspaceYHKfexU8oZMeta } from "/vercel/path0/packages/application/pages/app/settings/workspace.vue?macro=true";
import { default as indexPUGoP8ctVOMeta } from "/vercel/path0/packages/application/pages/index.vue?macro=true";
import { default as onboardingnOcrA8PNRMMeta } from "/vercel/path0/packages/application/pages/onboarding.vue?macro=true";
export default [
  {
    name: indexVLFNh06M3cMeta?.name ?? "any2table-app",
    path: indexVLFNh06M3cMeta?.path ?? "/any2table/app",
    meta: indexVLFNh06M3cMeta || {},
    alias: indexVLFNh06M3cMeta?.alias || [],
    redirect: indexVLFNh06M3cMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/any2table/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexidQkrUayBeMeta?.name ?? "any2table",
    path: indexidQkrUayBeMeta?.path ?? "/any2table",
    meta: indexidQkrUayBeMeta || {},
    alias: indexidQkrUayBeMeta?.alias || [],
    redirect: indexidQkrUayBeMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/any2table/index.vue").then(m => m.default || m)
  },
  {
    name: verifyAOMrCvjl9aMeta?.name ?? "any2table-verify",
    path: verifyAOMrCvjl9aMeta?.path ?? "/any2table/verify",
    meta: verifyAOMrCvjl9aMeta || {},
    alias: verifyAOMrCvjl9aMeta?.alias || [],
    redirect: verifyAOMrCvjl9aMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/any2table/verify.vue").then(m => m.default || m)
  },
  {
    name: dashboard0TrLSEL7kfMeta?.name ?? "app-dashboard",
    path: dashboard0TrLSEL7kfMeta?.path ?? "/app/dashboard",
    meta: dashboard0TrLSEL7kfMeta || {},
    alias: dashboard0TrLSEL7kfMeta?.alias || [],
    redirect: dashboard0TrLSEL7kfMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/dashboard.vue").then(m => m.default || m)
  },
  {
    name: errorVURPiQTj3uMeta?.name ?? "app-error",
    path: errorVURPiQTj3uMeta?.path ?? "/app/error",
    meta: errorVURPiQTj3uMeta || {},
    alias: errorVURPiQTj3uMeta?.alias || [],
    redirect: errorVURPiQTj3uMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/error.vue").then(m => m.default || m)
  },
  {
    name: _91fragment_45id_93vXfbKZK7kLMeta?.name ?? "app-fragment-fragmentid",
    path: _91fragment_45id_93vXfbKZK7kLMeta?.path ?? "/app/fragment/:fragmentid()",
    meta: _91fragment_45id_93vXfbKZK7kLMeta || {},
    alias: _91fragment_45id_93vXfbKZK7kLMeta?.alias || [],
    redirect: _91fragment_45id_93vXfbKZK7kLMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/fragment/[fragment-id].vue").then(m => m.default || m)
  },
  {
    name: indexVoNNwFj74nMeta?.name ?? "app-fragment",
    path: indexVoNNwFj74nMeta?.path ?? "/app/fragment",
    meta: indexVoNNwFj74nMeta || {},
    alias: indexVoNNwFj74nMeta?.alias || [],
    redirect: indexVoNNwFj74nMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/fragment/index.vue").then(m => m.default || m)
  },
  {
    name: indexzrWJlt7TpMMeta?.name ?? "app",
    path: indexzrWJlt7TpMMeta?.path ?? "/app",
    meta: indexzrWJlt7TpMMeta || {},
    alias: indexzrWJlt7TpMMeta?.alias || [],
    redirect: indexzrWJlt7TpMMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: loginAXWETL6ll0Meta?.name ?? "app-login",
    path: loginAXWETL6ll0Meta?.path ?? "/app/login",
    meta: loginAXWETL6ll0Meta || {},
    alias: loginAXWETL6ll0Meta?.alias || [],
    redirect: loginAXWETL6ll0Meta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/login.vue").then(m => m.default || m)
  },
  {
    name: _91project_45id_93H0nA0FmKtJMeta?.name ?? "app-project-projectid",
    path: _91project_45id_93H0nA0FmKtJMeta?.path ?? "/app/project/:projectid()",
    meta: _91project_45id_93H0nA0FmKtJMeta || {},
    alias: _91project_45id_93H0nA0FmKtJMeta?.alias || [],
    redirect: _91project_45id_93H0nA0FmKtJMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/project/[project-id].vue").then(m => m.default || m)
  },
  {
    name: _91prompt_45id_939umtKeSLaBMeta?.name ?? "app-prompt-promptid",
    path: _91prompt_45id_939umtKeSLaBMeta?.path ?? "/app/prompt/:promptid()",
    meta: _91prompt_45id_939umtKeSLaBMeta || {},
    alias: _91prompt_45id_939umtKeSLaBMeta?.alias || [],
    redirect: _91prompt_45id_939umtKeSLaBMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id].vue").then(m => m.default || m),
    children: [
  {
    name: craftXo9E2wrweKMeta?.name ?? "app-prompt-promptid-craft",
    path: craftXo9E2wrweKMeta?.path ?? "craft",
    meta: craftXo9E2wrweKMeta || {},
    alias: craftXo9E2wrweKMeta?.alias || [],
    redirect: craftXo9E2wrweKMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/craft.vue").then(m => m.default || m)
  },
  {
    name: evaluationjKyqfSjFrGMeta?.name ?? "app-prompt-promptid-evaluation",
    path: evaluationjKyqfSjFrGMeta?.path ?? "evaluation",
    meta: evaluationjKyqfSjFrGMeta || {},
    alias: evaluationjKyqfSjFrGMeta?.alias || [],
    redirect: evaluationjKyqfSjFrGMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/evaluation.vue").then(m => m.default || m)
  },
  {
    name: prompt_45app4k08kzEi5pMeta?.name ?? "app-prompt-promptid-prompt-app",
    path: prompt_45app4k08kzEi5pMeta?.path ?? "prompt-app",
    meta: prompt_45app4k08kzEi5pMeta || {},
    alias: prompt_45app4k08kzEi5pMeta?.alias || [],
    redirect: prompt_45app4k08kzEi5pMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/prompt-app.vue").then(m => m.default || m)
  },
  {
    name: testcXfqT28NoQMeta?.name ?? "app-prompt-promptid-test",
    path: testcXfqT28NoQMeta?.path ?? "test",
    meta: testcXfqT28NoQMeta || {},
    alias: testcXfqT28NoQMeta?.alias || [],
    redirect: testcXfqT28NoQMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/test.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVtpaDeze5nMeta?.name ?? "app-settings",
    path: indexVtpaDeze5nMeta?.path ?? "/app/settings",
    meta: indexVtpaDeze5nMeta || {},
    alias: indexVtpaDeze5nMeta?.alias || [],
    redirect: indexVtpaDeze5nMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membersezEMPcj8bQMeta?.name ?? "app-settings-members",
    path: membersezEMPcj8bQMeta?.path ?? "/app/settings/members",
    meta: membersezEMPcj8bQMeta || {},
    alias: membersezEMPcj8bQMeta?.alias || [],
    redirect: membersezEMPcj8bQMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/settings/members.vue").then(m => m.default || m)
  },
  {
    name: workspaceYHKfexU8oZMeta?.name ?? "app-settings-workspace",
    path: workspaceYHKfexU8oZMeta?.path ?? "/app/settings/workspace",
    meta: workspaceYHKfexU8oZMeta || {},
    alias: workspaceYHKfexU8oZMeta?.alias || [],
    redirect: workspaceYHKfexU8oZMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/settings/workspace.vue").then(m => m.default || m)
  },
  {
    name: indexPUGoP8ctVOMeta?.name ?? "index",
    path: indexPUGoP8ctVOMeta?.path ?? "/",
    meta: indexPUGoP8ctVOMeta || {},
    alias: indexPUGoP8ctVOMeta?.alias || [],
    redirect: indexPUGoP8ctVOMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingnOcrA8PNRMMeta?.name ?? "onboarding",
    path: onboardingnOcrA8PNRMMeta?.path ?? "/onboarding",
    meta: onboardingnOcrA8PNRMMeta || {},
    alias: onboardingnOcrA8PNRMMeta?.alias || [],
    redirect: onboardingnOcrA8PNRMMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: prompt_45app4k08kzEi5pMeta?.name ?? "app-prompt-promptid-data-extraction",
    path: prompt_45app4k08kzEi5pMeta?.path ?? "/app/prompt/:promptid?/data-extraction",
    meta: prompt_45app4k08kzEi5pMeta || {},
    alias: prompt_45app4k08kzEi5pMeta?.alias || [],
    redirect: prompt_45app4k08kzEi5pMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/prompt-app.vue").then(m => m.default || m)
  },
  {
    name: prompt_45app4k08kzEi5pMeta?.name ?? "app-prompt-promptid-compare-data",
    path: prompt_45app4k08kzEi5pMeta?.path ?? "/app/prompt/:promptid?/compare-data",
    meta: prompt_45app4k08kzEi5pMeta || {},
    alias: prompt_45app4k08kzEi5pMeta?.alias || [],
    redirect: prompt_45app4k08kzEi5pMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/prompt-app.vue").then(m => m.default || m)
  },
  {
    name: prompt_45app4k08kzEi5pMeta?.name ?? "app-prompt-promptid-signature-checker",
    path: prompt_45app4k08kzEi5pMeta?.path ?? "/app/prompt/:promptid?/signature-checker",
    meta: prompt_45app4k08kzEi5pMeta || {},
    alias: prompt_45app4k08kzEi5pMeta?.alias || [],
    redirect: prompt_45app4k08kzEi5pMeta?.redirect,
    component: () => import("/vercel/path0/packages/application/pages/app/prompt/[prompt-id]/prompt-app.vue").then(m => m.default || m)
  }
]