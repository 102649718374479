import { pgTable, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { type Static, Type } from '@sinclair/typebox'
import { workspace } from './workspace'
import { tableToSchema } from './shared'

export const apiKey = pgTable('api_key', {
    id: uuid('id').primaryKey().defaultRandom(),
    token: uuid('token').notNull().defaultRandom().unique(),
    name: varchar('name', { length: 256 }).notNull(),
    workspaceId: uuid('workspace_id').notNull().references(() => workspace.id, { onDelete: 'cascade' }),
    expiresAt: timestamp('expires_at').notNull(),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const apiKeyRelations = relations(apiKey, ({ one }) => ({
    workspace: one(workspace, {
        fields: [apiKey.workspaceId],
        references: [workspace.id],
    }),
}))

const { selectSchema, insertSchema } = tableToSchema(apiKey)

export const selectApiKeySchema = selectSchema
export const createApiKeySchema = Type.Pick(insertSchema, ['name', 'workspaceId'])
export const updateApiKeySchema = Type.Partial(Type.Pick(insertSchema, ['name', 'expiresAt']))

export type ApiKey = Static<typeof selectApiKeySchema>
export type CreateApiKeyPayload = Static<typeof createApiKeySchema>
export type UpdateApiKeyPayload = Static<typeof updateApiKeySchema>
