// plugins/vue-clerk.ts
import { clerkPlugin } from 'vue-clerk'

export default defineNuxtPlugin(async (nuxtApp) => {
    const { publishableKey } = useRuntimeConfig().public
    const serverInitialState = useState('clerk-initial-state', () => undefined)

    // Installing the `withClerkMiddleware` from `h3-clerk` adds an `auth` object to the context.
    // We can then use the `auth` object to get the initial state of the user.
    if (import.meta.server) {
        const authContext = useRequestEvent()?.context.auth
        serverInitialState.value = authContext ? pruneUnserializableFields(authContext) : undefined
    }

    nuxtApp.vueApp.use(clerkPlugin, {
        publishableKey,
        routerPush: (to: string) => navigateTo(to),
        routerReplace: (to: string) => navigateTo(to, { replace: true }),
        signInUrl: '/app/login',
        signUpUrl: '/app/login',
        initialState: serverInitialState.value,
    })
})

function pruneUnserializableFields(authContext: any) {
    return JSON.parse(JSON.stringify(authContext))
}
